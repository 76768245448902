/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

if (import.meta.env.VITE_APP_URL) {
    window.axios.defaults.baseURL = import.meta.env.VITE_APP_URL;
}

String.prototype.euro = function () {
    return parseFloat(this)
        .toLocaleString('nl-NL', {
            style:    'currency',
            currency: 'EUR',
        })
        .replace(',00', ',-');
};

